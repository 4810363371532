<template>
  <div class="wysiwyg">
    <p>
      點選箭頭，下有3種尺寸，點選合適的展間尺寸再進行後續物件設定及編輯，亦可選擇自訂，於右方框格輸入希望的數字格數，調整空間尺寸。亦可選擇自訂，於右方框格輸入希望的數字格數，調整空間尺寸。
    </p>
    <ol class="">
      <li>小(20坪)</li>
      <li>中(60坪)</li>
      <li>大(80坪)</li>
    </ol>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.link {
  @apply text-sm text-gray-400;
}
</style>
